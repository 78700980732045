<template>
  <div>
    <el-dialog
      v-dialogDrag
      title="新增现货销售"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="900px"
    >
      <div>
        <el-form ref="form" :rules="rules" :model="form" label-position="left">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="持仓ID" prop="holdId" label-width="100px">
                <el-select class="width-100" v-model="form.holdId">
                  <el-option
                    v-for="items in categoryOptions"
                    :key="items.holdId"
                    :label="items.holdId + '-' + items.className"
                    :value="items.holdId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :span="12"
              v-for="(item, index) of gradeIdOptions"
              :key="index"
            >
              <el-form-item
                :label="item.name"
                prop="specificationsName"
                label-width="100px"
              >
                <el-input disabled :value="item.value"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="数量" prop="quantity" label-width="100px">
                <el-input v-model.number="form.quantity"></el-input>
              </el-form-item>
            </el-col>
            <!-- </el-row>
            <el-row :gutter="20">-->
            <el-col :span="12">
              <el-form-item label="单价" prop="price" label-width="100px">
                <el-input v-model="form.price"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="总价" prop="zjPriceCalc" label-width="100px">
                <el-input v-model.number="zjPriceCalc" disabled></el-input>
              </el-form-item>
            </el-col>
            <!-- </el-row>
            <el-row :gutter="20">-->
            <el-col :span="12">
              <el-form-item label="重量" prop="weight" label-width="100px">
                <el-input v-model="form.weight">
                  <template slot="append">{{ form.util }}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="生产厂家" prop="manufacturer" label-width="100px">
                <el-input v-model="form.manufacturer"></el-input>
              </el-form-item>
            </el-col>-->
            <!-- </el-row>
            <el-row :gutter="20">-->
            <el-col :span="12">
              <el-form-item
                label="开票期限"
                prop="openTicketTime"
                label-width="100px"
              >
                <el-date-picker
                  class="width-100"
                  v-model="form.openTicketTime"
                  type="date"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="挂单有效时间"
                prop="effectiveTime"
                label-width="120px"
              >
                <el-date-picker
                  class="width-100"
                  v-model="form.effectiveTime"
                  type="date"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="是否接受议价"
                prop="bargainingStatus"
                label-width="120px"
              >
                <el-radio-group v-model="form.bargainingStatus" size="small">
                  <el-radio label="T">接受</el-radio>
                  <el-radio label="F">不接受</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="是否支持履约订金付款"
                prop="marginStatus"
                label-width="150px"
              >
                <el-radio-group v-model="form.marginStatus" size="small">
                  <el-radio label="T">支持</el-radio>
                  <el-radio label="F">不支持</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <!-- </el-row>
            <el-row :gutter="20">-->
            <el-col :span="12">
              <el-form-item
                label="业务负责人"
                prop="businessName"
                label-width="100px"
              >
                <el-input v-model="form.businessName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="联系电话"
                prop="businessPhone"
                label-width="100px"
              >
                <el-input v-model="form.businessPhone"></el-input>
              </el-form-item>
            </el-col>
            <!-- </el-row>
            <el-row :gutter="20">-->
            <el-col :span="12">
              <el-form-item
                label="交易方式"
                prop="tradeWay"
                label-width="100px"
              >
                <el-radio-group v-model="form.tradeWay" size="small">
                  <el-radio label="A">整批</el-radio>
                  <el-radio label="P">分销</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="form.tradeWay == 'P'">
              <el-form-item
                label="最小起订量"
                prop="minPickWeight"
                label-width="100px"
              >
                <el-input v-model="form.minPickWeight"></el-input>
              </el-form-item>
            </el-col>
            <!-- </el-row>
            <el-row :gutter="20">-->
            <el-col :span="12" v-if="form.tradeWay == 'P'">
              <el-form-item
                label="是否集采分销"
                prop="phaseType"
                label-width="100px"
              >
                <el-radio-group v-model="form.phaseType" size="small">
                  <el-radio label="T">使用</el-radio>
                  <el-radio label="F">不使用</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col
              :span="12"
              v-if="form.phaseType == 'T' && form.tradeWay == 'P'"
            >
              <el-form-item
                label="集采分销结束时间"
                prop="phaseEndTime"
                label-width="150px"
              >
                <el-date-picker
                  class="width-100"
                  v-model="form.phaseEndTime"
                  type="date"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <!-- <el-col :span="12" v-if="form.phaseType=='T'&&form.tradeWay=='P'">
              <el-form-item label="最低起订量" prop="phaseEndTime" label-width="150px">
                <el-date-picker
                  class="width-100"
                  v-model="form.phaseEndTime"
                  type="date"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col> -->
          </el-row>
          <el-row v-if="form.phaseType == 'T' && form.tradeWay == 'P'">
            <el-col
              :span="24"
              class="stepPriceCon"
              v-if="stepPriceList.length != 0"
            >
              <span
                v-for="(item, index) of stepPriceList"
                :key="index"
                @click="centerDialogVisible = true"
                >{{ item.lowWeight }}< X{{ index + 1 }} ≤{{ item.highWeight
                }}{{ form.util }} &nbsp; {{ item.price }}元/{{
                  form.util
                }}&nbsp;&nbsp;&nbsp;&nbsp;</span
              >
            </el-col>
            <el-col :span="12">
              <div
                class="stepPriceBtn"
                v-if="right.addStepPrice && stepPriceList.length == 0"
                @click="isaddStepPrices"
              >
                添加阶梯
              </div>
            </el-col>
          </el-row>
          <el-row
            :gutter="20"
            v-if="form.phaseType == 'T' && form.tradeWay == 'P'"
          >
            <el-col :span="12">
              <el-form-item
                label="集采订单重量"
                prop="phaseWeight"
                label-width="100px"
              >
                <el-input v-model="form.phaseWeight"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose()">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-dialogDrag
      title="添加阶梯价"
      :visible.sync="centerDialogVisible"
      width="600px"
      center
    >
      <div class="tips">温馨提示：重量区间前开后闭</div>
      <el-form ref="stepForm">
        <div v-for="(item, indexs) of setAllPriceList" :key="indexs">
          <el-form-item label="重量区间" label-width="100px">
            <el-col :span="10" style="padding:0">
              <el-input v-model="item.lowWeight" disabled>
                <template slot="append">{{ form.util }}</template>
              </el-input>
            </el-col>
            <el-col class="line" :span="4">< X{{ indexs + 1 }} ≤</el-col>
            <el-col :span="10" style="padding:0">
              <el-input
                v-model="item.highWeight"
                oninput="value=value.replace(/^(\-)*(\d+)\.(\d{0,2}).*$/g,'$1$2.$3')"
                @change="linerInput(item.lowWeight, item.highWeight, indexs)"
              >
                <template slot="append">{{ form.util }}</template>
              </el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="价格" prop="stepPrice" label-width="100px">
            <el-input
              v-model="item.price"
              oninput="value=value.replace(/^(\-)*(\d+)\.(\d{0,2}).*$/g,'$1$2.$3')"
            >
              <template slot="append">元/{{ form.util }}</template>
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addPrice()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    var checkAmount = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/g;
      // if (value == "") {
      //   callback(new Error("请输入数量"));
      // } else if (!reg.test(value)) {
      //   return callback(new Error("请输入正整数"));
      // } else
      if (value) {
        if (!this.form.holdId) {
          return callback(new Error("请先选择持仓ID"));
        } else if (!reg.test(value)) {
          return callback(new Error("请输入正整数"));
        } else if (value > Number(this.maxQuantity)) {
          return callback(new Error("超过可挂单数量"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var checkLowWight = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/g;
      if (!reg.test(value)) {
        return callback(new Error("请输入正整数"));
      } else {
        callback();
      }
    };
    var checkheightWight = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/g;
      if (!this.stepForm.lowWeight) {
        callback(new Error("请输入区间最低重量"));
      } else if (value == "") {
        callback(new Error("请输入区间最高重量"));
      } else if (!reg.test(value)) {
        return callback(new Error("请输入正整数"));
      } else if (this.stepForm.lowWeight >= value) {
        callback(new Error("最低重量应小于最高重量"));
      } else {
        callback();
      }
    };
    var checkPutWarehousDay = (rule, value, callback) => {
      const that = this;
      if (that.form.effectiveTime) {
        let currentTime = that.form.effectiveTime.getTime();
        let newValue = value.getTime();
        if (newValue <= currentTime) {
          return callback(new Error("预计入库时间应晚于挂单有效时间"));
        } else {
          callback();
        }
      }
      // let currentTime = new Date();
      // let yearTime = currentTime.getFullYear();
      // let monTime = currentTime.getMonth() + 1;
      // let dayTime = currentTime.getDate();
      // if (monTime < 10) {
      //   monTime = "0" + monTime;
      // }
      // if (dayTime < 10) {
      //   dayTime = "0" + dayTime;
      // }
      // let str = `${yearTime}${monTime}${dayTime}`;
      // if (value == "") {
      //   callback(new Error("请选择入库时间"));
      // } else if (Number(value) < Number(str)) {
      //   return callback(new Error("入库时间只能选择今天及以后"));
      // } else {
      //   callback();
      // }
    };
    var checkOpenTicketDay = (rule, value, callback) => {
      const that = this;
      if (value == "") {
        callback(new Error("请选择开票时间"));
      } else if (that.form.phaseType == "T") {
        let newValue = value.getTime();
        if (!that.form.phaseEndTime) {
          return callback(new Error("请选择集采分销结束时间"));
        } else if (newValue <= that.form.phaseEndTime.getTime()) {
          return callback(new Error("开票时间应晚于集采分销结束时间"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var checkeffectiveTime = (rule, value, callback) => {
      const that = this;
      if (value == "") {
        callback(new Error("请选择挂单有效时间"));
      } else if (!that.form.openTicketTime) {
        return callback(new Error("请选择开票期限"));
      } else if (value.getTime() >= that.form.openTicketTime.getTime()) {
        return callback(new Error("挂单有效时间应早于开票期限"));
      } else if (that.form.phaseType == "T") {
        let newValue = value.getTime();
        if (!that.form.phaseEndTime) {
          return callback(new Error("请选择集采分销结束时间"));
        } else if (newValue < that.form.phaseEndTime.getTime()) {
          return callback(
            new Error("挂单有效时间应晚于或等于集采分销结束时间")
          );
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var checkPhaseType = (rule, value, callback) => {
      if (this.form.tradeWay == "P" && !value) {
        return callback(new Error("请选择是否使用集采分销"));
      } else {
        callback();
      }
    };
    var checkPhaseEndTime = (rule, value, callback) => {
      if (this.form.phaseType == "T" && !value) {
        return callback(new Error("请选择集采分销结束时间"));
      } else {
        callback();
      }
    };
    var checkPhaseWeight = (rule, value, callback) => {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (this.form.phaseType == "T" && !value) {
        return callback(new Error("请输入集采分销达到重量"));
      } else if (
        this.form.phaseType == "T" &&
        (!reg.test(value) || Number(value) <= 0)
      ) {
        return callback(new Error("单价必须是正数且不能超过两位小数"));
      } else if (
        this.form.phaseType == "T" &&
        Number(value) > Number(this.form.weight)
      ) {
        return callback(new Error("请集采分销重量不能大于挂单重量"));
      } else if (
        this.form.phaseType == "T" &&
        Number(value) < Number(this.form.minPickWeight)
      ) {
        return callback(new Error("请集采分销重量不能小于最小起订量"));
      } else {
        callback();
      }
    };
    var checkMinPickWeight = (rule, value, callback) => {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (this.form.tradeWay == "P" && !value) {
        return callback(new Error("请输入最小起订量"));
      } else if (
        this.form.tradeWay == "P" &&
        (!reg.test(value) || Number(value) <= 0)
      ) {
        return callback(new Error("最小起订量必须是正数且不能超过两位小数"));
      } else if (
        this.form.tradeWay == "P" &&
        Number(value) > Number(this.form.weight)
      ) {
        return callback(new Error("最小起订量不能大于挂单重量"));
      } else {
        callback();
      }
    };

    var checkPutHoldWeight = (rule, value, callback) => {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (value == "") {
        return callback(new Error("请输入重量"));
      } else if (!reg.test(value) || Number(value) <= 0) {
        return callback(new Error("重量必须是正数且不能超过两位小数"));
      } else if (!this.form.holdId) {
        return callback(new Error("请先选择持仓ID"));
      } else if (value > Number(this.maxWeight)) {
        return callback(new Error("超过可挂单重量"));
      } else {
        callback();
      }
    };
    var checkPrice = (rule, value, callback) => {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (value == "") {
        return callback(new Error("请输入单价"));
      } else if (!reg.test(value) || Number(value) <= 0) {
        return callback(new Error("单价必须是正数且不能超过两位小数"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible1: false,
      dialogVisible: false,
      centerDialogVisible: false,
      gradeName: null,
      lowWeight: null,
      highWeight: null,
      stepPrice: null,
      stepPriceList: [],
      setAllPriceList: [{ lowWeight: "0", highWeight: "", price: "" }],
      right: {
        addStepPrice: false
      },
      form: {
        bargainingStatus: null, //是否可以议价T：可以F：不可以
        businessName: null, //业务负责人
        businessPhone: null, // 电话
        holdId: null, // 持仓id 毕传
        manufacturer: null, //厂家
        openTicketTime: null, // 开票期限
        phaseEndTime: null, //集采分销结束时间
        phaseType: null, //是否开启集采分销T：开F：不开
        phaseWeight: null, //集采分销达到重量
        price: null, //单价
        quantity: null, //挂单数量
        sourceId: null, //来源W：现货J：竞价C:预售
        tradeWay: null, // A：整批P：分销
        weight: null, // 挂单重量
        util: null, // 重量单位
        effectiveTime: null,
        marginStatus: null, // 是否支持履约订金付款T是F否
        minPickWeight: null // 最小起订量
      },
      newForm: {
        code: null,
        marginPassWord: null
      },
      steprules: {
        lowWeight: [
          { required: true, message: "请输入区间最低重量", trigger: "change" },
          { validator: checkLowWight, trigger: "blur" }
        ],
        highWeight: [
          { required: true, message: "请输入区间最高重量", trigger: "change" },
          { validator: checkheightWight, trigger: "blur" }
        ]
      },
      rules: {
        holdId: [
          { required: true, message: "请选择持仓ID", trigger: "change" }
        ],
        bargainingStatus: [
          { required: true, message: "请选择是否可议价", trigger: "change" }
        ],
        marginStatus: [
          {
            required: true,
            message: "请选择是否支持履约订金付款",
            trigger: "change"
          }
        ],
        openTicketTime: [
          { required: true, message: "请选择开票期限", trigger: "change" },
          { validator: checkOpenTicketDay, trigger: "blur" }
        ],
        effectiveTime: [
          { required: true, message: "请选择挂单有效时间", trigger: "change" },
          { validator: checkeffectiveTime, trigger: "change" }
        ],
        price: [
          { required: true, message: "请输入价格", trigger: "change" },
          { validator: checkPrice, trigger: "change" }
        ],
        quantity: [
          { required: false, message: "请输入数量", trigger: "change" },
          { validator: checkAmount, trigger: "blur" }
        ],
        phaseType: [
          {
            required: false,
            message: "请选择是否使用集采分销",
            trigger: "change"
          },
          { validator: checkPhaseType, trigger: "blur" }
        ],
        phaseEndTime: [
          {
            required: false,
            message: "请选择集采分销结束时间",
            trigger: "change"
          },
          { validator: checkPhaseEndTime, trigger: "blur" }
        ],
        phaseWeight: [
          { required: false, message: "请输入集采分销重量", trigger: "change" },
          { validator: checkPhaseWeight, trigger: "blur" }
        ],
        weight: [
          { required: true, message: "请输入重量", trigger: "change" },
          {
            validator: checkPutHoldWeight,
            trigger: "change"
          }
        ],
        minPickWeight: [
          { required: true, message: "请输入最小起订量", trigger: "change" },
          {
            validator: checkMinPickWeight,
            trigger: "change"
          }
        ],
        manufacturer: [
          { required: false, message: "请输入生产厂家", trigger: "change" }
        ],
        tradeWay: [
          { required: true, message: "请选择交易方式", trigger: "change" }
        ],
        businessName: [
          { required: true, message: "请输入业务负责人", trigger: "change" }
        ],
        businessPhone: [
          { required: true, message: "请输入联系电话", trigger: "change" }
          // {
          //   pattern: /^1[3456789]\d{9}$/,
          //   message: "手机号格式不正确",
          //   trigger: "blur"
          // }
        ]
      },
      newFormRules: {
        // code: [{ required: true, message: "请输入短信验证码", trigger: "change" }],
        // marginPassWord: [{ required: true, message: "请输入资金密码", trigger: "change" }],
      },
      putWarehousTimePicker: {
        disabledDate: time => {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      effectiveTimePicker: {
        disabledDate: time => {
          return time.getTime() < Date.now();
        }
      },
      categoryProps: {
        label: "holdId",
        value: "holdId"
      },
      categoryOptions: [],
      gradeIdOptions: [],
      packagIdOptions: [],
      specificationsIdOptions: [],
      storageIdOptions: [],
      zjPriceCalc: null,
      isAddLoading: false,
      actionUrl: window.g.APP_USER_PATH_UPLOAD,
      postData: {
        uid: this.$store.state.common.sessionInfo.firmId,
        deleteStatus: 0,
        width: null,
        height: null,
        size: null
      },
      validButtonText: "发送验证码",
      validBtnDisabled: false,
      maxWeight: null,
      maxQuantity: null,
      newCommodityId: null
    };
  },
  watch: {
    "form.holdId": {
      handler(val, oldVal) {
        this.calcUnit();
        this.setAllPriceList = [{ lowWeight: "0", highWeight: "", price: "" }];
        this.stepPriceList = [];
      }
    },
    "form.weight": {
      handler(val, oldVal) {
        this.calcZjPrice();
      }
    },
    "form.price": {
      handler(val, oldVal) {
        this.calcZjPrice();
      }
    }
  },
  computed: {
    // deposit() {
    //   return util.floatMul(this.zjPriceCalc, 0.2);
    // }
  },
  methods: {
    handledialogVisible1() {
      this.dialogVisible1 = false;
    },
    showDialog(holdId) {
      this.dialogVisible = true;
      this.getCommodityCategory(holdId);
      this.getRights();
    },
    getRights() {
      this.loadRight().then(loadResult => {
        if (loadResult == "loadRightSuccess") {
          this.right.addStepPrice = this.isHasRight(
            "tc-trade-setPhaseHangResourcePrice"
          );
        }
      });
    },
    linerInput(lowValue, value, indexs) {
      if (indexs == this.setAllPriceList.length - 1) {
        if (Number(value) > Number(this.form.weight)) {
          return this.$EL_MESSAGE("重量区间最大值不能大于挂单重量");
        }
        if (Number(value) <= Number(lowValue)) {
          return this.$EL_MESSAGE("重量区间最大值应大于最小值");
        } else if (Number(value) < Number(this.form.weight)) {
          let obj = {};
          obj.lowWeight = value;
          obj.highWeight = "";
          obj.price = "";
          this.setAllPriceList.push(obj);
        }
      } else {
        this.setAllPriceList[indexs + 1].lowWeight = value;
        if (Number(value) == Number(this.form.weight)) {
          this.setAllPriceList.splice(indexs + 1);
        } else if (Number(value) <= Number(lowValue)) {
          this.setAllPriceList.splice(indexs + 1);
          return this.$EL_MESSAGE("重量区间最大值应大于最小值");
        } else if (Number(value) > Number(this.form.weight)) {
          this.setAllPriceList.splice(indexs + 1);
          return this.$EL_MESSAGE("重量区间最大值不能大于挂单重量");
        }
      }
    },
    isaddStepPrices() {
      if (this.form.holdId && this.form.weight) {
        this.setAllPriceList = [{ lowWeight: "0", highWeight: "", price: "" }];
        this.stepPriceList = [];
        this.centerDialogVisible = true;
      } else {
        this.$EL_MESSAGE("请选择持仓ID或输入挂单重量");
      }
    },
    modifyStepPrice(item, index) {
      // 修改阶梯价
      this.lowWeight = item.lowWeight;
      this.highWeight = item.highWeight;
      this.stepPrice = item.price;
      this.centerDialogVisible = true;
      this.stepPriceList.splice(index, 1);
    },
    addPrice() {
      const that = this;
      let len = this.setAllPriceList.length;
      if (len == 0) {
        return this.$EL_MESSAGE("请输入阶梯区间");
      }
      for (let i = 0; i < len; i++) {
        if (
          !this.setAllPriceList[i].price ||
          this.setAllPriceList[i].lowWeight == undefined ||
          this.setAllPriceList[i].lowWeight == null ||
          !this.setAllPriceList[i].highWeight
        ) {
          return this.$EL_MESSAGE("阶梯数据不能为空");
        }
      }
      if (
        Number(this.setAllPriceList[len - 1].highWeight) >
        Number(this.form.weight)
      ) {
        return this.$EL_MESSAGE("最大区间重量不能大于挂单重量");
      }
      if (
        Number(this.setAllPriceList[len - 1].lowWeight) ==
        Number(this.setAllPriceList[len - 1].highWeight)
      ) {
        return this.$EL_MESSAGE("重量区间最大值应大于最小值");
      }
      // let obj = {};
      // obj.lowWeight = this.lowWeight;
      // obj.highWeight = this.highWeight;
      // obj.price = this.stepPrice;
      this.stepPriceList = this.setAllPriceList;
      this.centerDialogVisible = false;
      // this.lowWeight = null;
      // this.highWeight = null;
      // this.stepPrice = null;
    },
    setTime(e, vueThis) {
      let _this = this;
      e--;
      vueThis.validButtonText = e + "s";
      if (e > 0) {
        setTimeout(function() {
          _this.setTime(e, vueThis);
        }, 1000);
      } else {
        vueThis.validButtonText = "发送验证码";
        vueThis.validBtnDisabled = false;
      }
    },
    calcUnit() {
      this.gradeIdOptions = [];
      for (let j = 0; j < this.categoryOptions.length; j++) {
        if (this.categoryOptions[j].holdId == this.form.holdId) {
          let goodsGroupCommonSpec = JSON.parse(
            this.categoryOptions[j].specificationsName
          );
          for (let key in goodsGroupCommonSpec) {
            console.log(key);
            let obj = {};
            obj.name = key;
            obj.value = goodsGroupCommonSpec[key];
            this.gradeIdOptions.push(obj);
          }
          if (this.categoryOptions[j].manufacturer) {
            let obj = {};
            obj.name = "生产厂家";
            obj.value = this.categoryOptions[j].manufacturer;
            this.gradeIdOptions.push(obj);
          }
          if (this.categoryOptions[j].placeOrigin) {
            let obj = {};
            obj.name = "产地";
            obj.value = this.categoryOptions[j].placeOrigin;
            this.gradeIdOptions.push(obj);
          }
          if (this.categoryOptions[j].brand) {
            let obj = {};
            obj.name = "品牌";
            obj.value = this.categoryOptions[j].brand;
            this.gradeIdOptions.push(obj);
          }
          this.form.util = this.categoryOptions[j].unit;
          this.maxWeight = util.floatSub(
            this.categoryOptions[j].holdWeight,
            this.categoryOptions[j].frozenHoldWeight
          );
          this.maxQuantity = this.categoryOptions[j].quantity;
          this.form.quantity = this.categoryOptions[j].quantity;
          this.form.weight = this.maxWeight;
        }
      }
    },
    sureStepPrice(value) {
      protocolFwd.param_setPhaseHangResourcePrice.param.hangResourcesId = value;
      protocolFwd.param_setPhaseHangResourcePrice.param.obj = this.stepPriceList;
      http
        .postFront(protocolFwd.param_setPhaseHangResourcePrice)
        .then(response => {
          this.$EL_MESSAGE(response.data.message);
          this.dialogVisible = false;
          this.dialogVisible1 = false;
          this.$EL_MESSAGE("提交成功！待后台审核成功后方可发布");
          this.$refs["form"].resetFields();
          this.$emit("reLoad");
          this.$router.push("/sellerCenter/resource/spotResource");
        });
    },
    sendValidNo() {
      protocol.param_authn_margin_sendCaptcha.param.id = 30;
      http.postFront(protocol.param_authn_margin_sendCaptcha).then(response => {
        this.$EL_MESSAGE(response.data.message);
        const that = this;
        this.validBtnDisabled = true;
        this.setTime(60, that);
      });
    },
    getCommodityCategory(holdId) {
      const that = this;
      protocolFwd.param_queryHold.param.page = 0;
      protocolFwd.param_queryHold.param.size = 10000;
      // protocolFwd.param_queryHold.param.sort = [{property: "applyWarehousId", direction: "DESC"}];
      protocolFwd.param_queryHold.param.holdType = ["X", "B"];
      protocolFwd.param_queryHold.param.holdId = null;
      protocolFwd.param_queryHold.param.auditHoldStatus = "T";
      protocolFwd.param_queryHold.param.sellStatus = "T";
      protocolFwd.param_queryHold.param.specificationsId = null;
      protocolFwd.param_queryHold.param.holdStatus = null;
      protocolFwd.param_queryHold.param.specificationsName = null;
      protocolFwd.param_queryHold.param.storageId = null;
      protocolFwd.param_queryHold.param.unitId = null;
      // protocolFwd.param_queryHold.param.specificationsName = {};
      http.postFront(protocolFwd.param_queryHold).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.categoryOptions = value.content;
          if (holdId) {
            this.form.holdId = holdId;
            this.categoryOptions.forEach(row => {
              if (holdId == row.holdId) {
                that.form.util = row.unit;
                that.maxWeight = util.floatSub(
                  row.holdWeight,
                  row.frozenHoldWeight
                );
              }
            });
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    deleteCategoryOptionsLast(data) {
      for (const item of data) {
        if (item.children.length !== 0) {
          this.deleteCategoryOptionsLast(item.children);
        } else {
          delete item.children;
        }
      }
    },
    getGradeIdOptions() {
      http.postFront(protocolFwd.param_getCommodityGrade).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.gradeIdOptions = [];
          for (let i = 0; i < value.length; i++) {
            if (value[i].value == "国标") {
              this.gradeIdOptions.push(value[i]);
            }
          }
          this.gradeName = this.gradeIdOptions[0].value;
          this.form.gradeId = this.gradeIdOptions[0].gradeId;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getPackagIdOptions() {
      http.postFront(protocolFwd.param_getCommodityPackage).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.packagIdOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getSpecificationsIdOptions() {
      http.postFront(protocolFwd.param_getComSpecifications).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.specificationsIdOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getStorageIdOptions() {
      http.postFront(protocolFwd.param_getStorage).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.storageIdOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    calcWeight() {
      let specificationsValue = null;
      for (let i = 0; i < this.specificationsIdOptions.length; i++) {
        if (
          this.form.specificationsId ==
          this.specificationsIdOptions[i].specificationsId
        ) {
          specificationsValue = parseFloat(
            this.specificationsIdOptions[i].value
          );
        }
      }
      this.form.weight = util.floatMul(this.form.quantity, specificationsValue);
    },
    calcZjPrice() {
      this.zjPriceCalc = util.floatMul(this.form.price, this.form.weight);
    },
    handleAvatarSuccess(res, file) {
      this.form.img = res.value;
    },
    beforeAvatarUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 2MB!");
      }
      return testmsg && isLt2M;
    },
    // confirm() {
    //   this.$refs["form"].validate(valid => {
    //     if (valid) {
    //       this.dialogVisible1 = true;
    //       console.log(this.form);
    //     }
    //   });
    // },
    confirm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          protocolFwd.param_sellHangStockOrder.param.bargainingStatus = this.form.bargainingStatus;
          protocolFwd.param_sellHangStockOrder.param.holdId = this.form.holdId;
          protocolFwd.param_sellHangStockOrder.param.openTicketTime = this.form.openTicketTime;
          protocolFwd.param_sellHangStockOrder.param.effectiveTime =
            this.form.effectiveTime.getTime() + 60900000;
          protocolFwd.param_sellHangStockOrder.param.quantity = this.form.quantity;
          protocolFwd.param_sellHangStockOrder.param.price = this.form.price;
          protocolFwd.param_sellHangStockOrder.param.tradeWay = this.form.tradeWay;
          protocolFwd.param_sellHangStockOrder.param.weight = this.form.weight;
          protocolFwd.param_sellHangStockOrder.param.manufacturer = this.form.manufacturer;
          protocolFwd.param_sellHangStockOrder.param.businessName = this.form.businessName;
          protocolFwd.param_sellHangStockOrder.param.businessPhone = this.form.businessPhone;
          protocolFwd.param_sellHangStockOrder.param.marginStatus = this.form.marginStatus;
          // protocolFwd.param_sellHangStockOrder.param.marginStatus = this.form.marginStatus;
          protocolFwd.param_sellHangStockOrder.param.sourceId = "W";
          if (this.form.tradeWay == "P") {
            protocolFwd.param_sellHangStockOrder.param.phaseType = this.form.phaseType;
            protocolFwd.param_sellHangStockOrder.param.phaseWeight = this.form.phaseWeight;
            protocolFwd.param_sellHangStockOrder.param.minPickWeight = this.form.minPickWeight;
            if (this.form.phaseType == "T") {
              protocolFwd.param_sellHangStockOrder.param.phaseEndTime =
                this.form.phaseEndTime.getTime() + 60600000;
            } else {
              protocolFwd.param_sellHangStockOrder.param.phaseEndTime = null;
            }
          } else {
            protocolFwd.param_sellHangStockOrder.param.minPickWeight = this.form.weight;
            protocolFwd.param_sellHangStockOrder.param.phaseType = "F";
            protocolFwd.param_sellHangStockOrder.param.phaseWeight = null;
            protocolFwd.param_sellHangStockOrder.param.phaseEndTime = null;
          }
          if (this.form.phaseType == "T" && this.form.tradeWay == "P") {
            protocolFwd.param_sellHangStockOrder.param.phaseStatus = "W";
          } else {
            protocolFwd.param_sellHangStockOrder.param.phaseStatus = null;
          }
          const that = this;
          this.isAddLoading = true;
          http
            .postFront(protocolFwd.param_sellHangStockOrder)
            .then(response => {
              const { code, message, value } = response.data;
              this.isAddLoading = false;
              if (code == 0) {
                if (that.form.phaseType == "T") {
                  that.sureStepPrice(value);
                  return;
                }
                this.dialogVisible = false;
                this.dialogVisible1 = false;
                this.$EL_MESSAGE("提交成功！待后台审核成功后方可发布");
                this.$refs["form"].resetFields();
                this.$emit("reLoad");
                this.$router.push("/sellerCenter/resource/spotResource");
              } else {
                this.$EL_MESSAGE(message);
              }
            })
            .catch(() => {
              this.isAddLoading = false;
            });
        }
      });
    },
    handleClose() {
      this.form = {
        bargainingStatus: null, //是否可以议价T：可以F：不可以
        businessName: null, //业务负责人
        businessPhone: null, // 电话
        holdId: null, // 持仓id 毕传
        manufacturer: null, //厂家
        openTicketTime: null, // 开票期限
        phaseEndTime: null, //集采分销结束时间
        phaseType: null, //是否开启集采分销T：开F：不开
        phaseWeight: null, //集采分销达到重量
        price: null, //单价
        quantity: null, //挂单数量
        sourceId: null, //来源W：现货J：竞价C:预售
        tradeWay: null, // A：整批P：分销
        weight: null, // 挂单重量
        util: null, // 重量单位
        effectiveTime: null,
        minPickWeight: null
      };
      this.centerDialogVisible = false;
      this.lowWeight = null;
      this.highWeight = null;
      this.stepPrice = null;
      this.stepPriceList = [];
      this.setAllPriceList = [{ lowWeight: "0", highWeight: "", price: "" }];
      //  this.newForm = {
      //   code: null,
      //   marginPassWord: null
      // }
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
        // this.$refs["newForm"].clearValidate();
      });
      this.dialogVisible = false;
      this.dialogVisible1 = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.tip {
  color: #cf4640;
  position: absolute;
  top: 9px;
  left: 66px;
}
.tips {
  color: #cf4640;
  position: absolute;
  top: 50px;
  left: 55px;
}
.stepPriceCon {
  line-height: 30px;
}
.stepPriceBtn {
  width: 80px;
  height: 30px;
  line-height: 30px;
  margin: 10px 30px;
  text-align: center;
  border: 1px solid #66b1ff;
  background-color: #66b1ff;
  color: #fff;
}
</style>
